import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Layout from "gatsby-theme-stack/src/integration-layout";
export const _frontmatter = {
  "title": "Zapier",
  "author": "admin",
  "date": "2019-01-01T00:00:00.000Z",
  "image": "img/zapier.svg"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Zapier`}</h1>
    <p>{`Automate your business using Zapier`}</p>
    <hr></hr>
    <p>{`Zapier is an automation platform integrating more than 1000 apps. Link Crisp with Zapier and start integrating Crisp with other platforms and automate your workflow using zaps.`}</p>
    <p>{`Benefits of using Crisp and Zapier:`}</p>
    <ul>
      <li parentName="ul">{`Automate stuff with no coding Skills`}</li>
      <li parentName="ul">{`Integrate Crisp with other services`}</li>
      <li parentName="ul">{`Send contacts to your favorite CRM`}</li>
      <li parentName="ul">{`Keep a record of previous conversations in a Google Spreadsheet`}</li>
      <li parentName="ul">{`Save transcripts in Dropbox`}</li>
      <li parentName="ul">{`Enrich new leads through an enrichment API`}</li>
      <li parentName="ul">{`Innovate faster with no technical skills`}</li>
      <li parentName="ul">{`Stick with the tools that fit your business`}</li>
      <li parentName="ul">{`Anyone can build workflows with a few clicks`}</li>
    </ul>



    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      